
import { defineComponent, watch, ref } from 'vue';
import { SERVICE_LEVEL, getSharedType } from '../list/config';
import { getClassificationName, getTagsName } from '../util';
import { getAllTags } from '@/api/settings/tags';
import { getClassificationList } from '@/api/settings/classification';
import { userInfo } from '@/layout/messageCenter/user-info';
import { useRoute } from 'vue-router';

interface Column {
  key: string;
  label: string;
  render?: Function;
}

export default defineComponent({
  name: 'ServiceBase',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const newColumns = ref([] as any);
    const tagList = ref([] as any);
    const classificationList = ref([] as any);
    const fetchData = async () => {
      const [tagReq, classificationReq] = await Promise.all([getAllTags(), getClassificationList()]);
      tagList.value = tagReq.data;
      classificationList.value = classificationReq.data;
    };
    const route = useRoute();
    console.log(route.path, 'this is route');
    fetchData();

    const columns: Column[] = [
      {
        key: 'name',
        label: '服务英文名',
      },
      {
        key: 'zhName',
        label: '服务中文名',
      },
      {
        key: 'level',
        label: '级别',
        render(col: Column, row: any) {
          return SERVICE_LEVEL[row.level];
        },
      },
      {
        key: 'type',
        label: '类型',
      },
      {
        key: 'origin',
        label: '来源',
        render(col: Column, row: any) {
          if (typeof row.tenantId === 'string') {
            return '---';
          }
          return row.tenantId === userInfo.value.tenantId ? '自研新建' : '平台共享';
        },
      },
      {
        key: 'developer',
        label: '开发方',
      },
      {
        key: 'platformShareType',
        label: '权限',
        render(col: Column, row: any) {
          const { path } = route;
          if (path.includes('shared')) {
            return row.platformShareType === 0 ? '未共享' : getSharedType(row.platformShareType);
          }
          if (path.includes('distribute')) {
            if (row.tenantId === userInfo.value.tenantId) {
              return '引用，克隆';
            }
            return row.platformShareType === 0 ? '未共享' : getSharedType(row.platformShareType);
          }
          return getSharedType(row.platformShareType);
        },
      },
      {
        key: 'serviceVersion',
        label: '版本',
      },
      {
        key: 'classification',
        label: '分类',
        render(col: Column, row: any) {
          return getClassificationName(row.classification, classificationList.value);
        },
      },
      {
        key: 'tags',
        label: '标签',
        render(col: Column, row: any) {
          return getTagsName((row.tag || '').split(','), tagList.value);
        },
      },
      {
        key: 'thumbnail',
        label: 'logo',
      },
    ];
    const setColumns = (info: any) => {
      if (props?.info?.isApp) {
        columns[0].label = '应用英文名';
        columns[1].label = '应用中文名';
      }
      newColumns.value = columns.map((item) => {
        const column = item;
        const { render } = column;
        column.render = () => render?.(column, info) || info[item.key];
        return column;
      });
    };

    if (props.info) {
      setColumns(props.info);
    }

    watch(
      () => props.info,
      (newValue) => {
        setColumns(newValue);
      },
    );
    return {
      columns: newColumns,
      getClassificationName,
      getTagsName,
      tagList,
      classificationList,
      userInfo,
    };
  },
});
