import { SYSTEM_APIS } from '@/views/service-management/api/config';

export interface ServiceInfo {
  id: string | number;
  name: string;
  zhName: string;
  level: number;
  type: string;
  origin: string;
  developer: string;
  platformShareType: string;
  serviceVersion: string;
  classification: string;
  tags: string;
  tenantId: number | string;
  apiList: any[];
  models: any[];
  appearance: number;
  snapshotNo?: string;
}

/**
 * 服务详情
 * @param data
 */
export const parseServiceInfo = (data: any, apiList: any[]): ServiceInfo => ({
  id: data.baseInfo.id,
  name: data.baseInfo.name,
  zhName: data.baseInfo.description,
  level: 1,
  type: '业务服务',
  origin: '',
  developer: '',
  platformShareType: '---',
  serviceVersion: data.baseInfo.serviceVersion,
  classification: data.baseInfo.classification,
  tags: data.baseInfo.tag,
  tenantId: '',
  apiList: data.baseInfo.appearance === 1 ? [...(apiList || []), ...SYSTEM_APIS] : [...(apiList || [])],
  models: data.models,
  appearance: data.baseInfo.appearance,
});

/**
 * 仓库详情
 */
export const parseRepositoryInfo = (data: any): ServiceInfo => {
  if (data.snapshotInfo) {
    return {
      id: data.id,
      name: data.skuName,
      zhName: data.skuNameZh,
      level: data.snapshotInfo.level,
      type: '业务服务',
      origin: '',
      developer: data?.snapshotInfo?.developer,
      platformShareType: data.platformShareType,
      serviceVersion: data.skuLatestVersion,
      classification: data.snapshotInfo.classification,
      tags: data.snapshotInfo.tag,
      tenantId: data.tenantId,
      snapshotNo: data.snapshotInfo.snapshotNo,
      apiList:
        data.snapshotInfo.appearance === 1
          ? [
              ...(JSON.parse(data.snapshotInfo.config || '{}').apiSchemas?.map((item: any) => item) || []),
              ...SYSTEM_APIS,
            ]
          : [...(JSON.parse(data.snapshotInfo.config || '{}').apiSchemas?.map((item: any) => item) || [])],
      models: JSON.parse(data.snapshotInfo.config || '{}').modelInfos?.map((item: any) => item) || [],
      appearance: data.snapshotInfo.appearance,
    };
  }
  return {
    id: data.id,
    name: data.skuName,
    zhName: data.skuNameZh,
    level: 0,
    type: '业务服务',
    origin: '',
    developer: '',
    platformShareType: data.platformShareType,
    serviceVersion: data.skuLatestVersion,
    classification: '',
    tags: '',
    tenantId: data.tenantId,
    apiList: [],
    models: [],
    appearance: 0,
  };
};

export const parseRepositoryAppInfo = (data: any) => {
  const item = { ...data };
  item.name = data.skuName;
  item.zhName = data.skuNameZh;
  item.serviceVersion = data.skuLatestVersion;
  return item;
};
