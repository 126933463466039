import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed90e43a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-base" }
const _hoisted_2 = { style: {"color":"#606266"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_service_name = _resolveComponent("service-name")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, {
      gutter: 20,
      class: "columns-wrap"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
          return (_openBlock(), _createBlock(_component_el_col, {
            span: 12,
            key: col.key
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_row, { gutter: 20 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    span: 4,
                    style: {"text-align":"right"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(col.label), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_col, {
                    span: 18,
                    offset: 1
                  }, {
                    default: _withCtx(() => [
                      (col.key === 'name')
                        ? (_openBlock(), _createBlock(_component_service_name, {
                            key: 0,
                            name: _ctx.info.name
                          }, null, 8, ["name"]))
                        : (col.key === 'thumbnail')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (col.render())
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: col.render(),
                                    class: "img-style"
                                  }, null, 8, _hoisted_3))
                                : (_openBlock(), _createElementBlock("span", _hoisted_4, "暂无"))
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(col.render()), 1)
                            ], 64))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}