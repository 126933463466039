
import { defineComponent, ref } from 'vue';
import { getRepositoryHistory, getRepositoryHistoryForApp } from '@/api/repository';
import VersionInfoDialog from './Version-Info-Dialog.vue';
import { parseDescriptionHtml } from '../util';
import { useRoute } from 'vue-router';
import dateFormat from '@/utils/date-format';
export default defineComponent({
  name: 'ServiceHistory',
  components: {
    VersionInfoDialog,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    // 用于判断是否为仓库调用，后续待调整
    isService: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loading = ref(true);
    const histories = ref([] as any[]);
    const versionInfoDialogRef = ref(null as any);
    const router = useRoute();
    const { params } = router;

    const statusMap = {
      10: '发版成功',
      2: '发版失败',
      1: '发版中',
    };

    const statusColor = {
      10: '#0ABF5B',
      2: '#E54545',
      1: '#FF9D00',
    };

    const fetchData = async () => {
      loading.value = true;
      try {
        const query: any = {};
        let data: any = [];
        if (!props.isService) {
          query.serviceName = props.info.name;
          query.skuType = props.info.isApp ? 1 : 2;
          data = ((await getRepositoryHistoryForApp(query)) as any).data;
        } else {
          query.serviceId = params.id;
          data = ((await getRepositoryHistory(query)) as any).data;
        }
        histories.value = data.map((item: any, index: number) => {
          const newItem = { ...item };
          newItem.collapsed = index > 0;
          return newItem;
        });
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };

    const handleShowVersionInfo = (row: any) => {
      versionInfoDialogRef.value.handleOpen(row.snapshotNo, {
        isService: props.isService,
        ...props.info,
      });
    };
    fetchData();
    return {
      loading,
      histories,
      versionInfoDialogRef,
      handleShowVersionInfo,
      parseDescriptionHtml,
      statusMap,
      statusColor,
      params,
      dateFormat,
    };
  },
});
