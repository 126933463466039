import { genId } from '@/utils/util';

export const METHOD_TYPES = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];
export const SYSTEM_APIS = [
  {
    id: genId(),
    $id: genId(),
    name: 'save',
    methodType: 'POST',
    isSystem: true,
    url: '/save',
    description: '保存',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'delete',
    methodType: 'POST',
    isSystem: true,
    url: '/delete',
    description: '删除',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'edit',
    methodType: 'POST',
    isSystem: true,
    url: '/edit',
    description: '更新',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'get',
    methodType: 'GET',
    isSystem: true,
    url: '/get',
    description: '详情',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'list',
    methodType: 'GET',
    isSystem: true,
    url: '/list',
    description: '列表',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'pages',
    methodType: 'POST',
    isSystem: true,
    url: '/pages',
    description: '分页检索接口',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'batchDelete',
    methodType: 'POST',
    isSystem: true,
    url: '/batch/delete',
    description: '批量删除',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'batchGet',
    methodType: 'POST',
    isSystem: true,
    url: '/batch/get',
    description: '批量查询',
    modelId: null,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'batchSave',
    methodType: 'POST',
    isSystem: true,
    url: '/batch/save',
    description: '批量保存',
    modelId: null,
    reserved: true,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'batchUpdate',
    methodType: 'POST',
    isSystem: true,
    url: '/batch/update',
    description: '批量更新',
    modelId: null,
    reserved: true,
  },
  {
    id: genId(),
    $id: genId(),
    name: 'export',
    methodType: 'POST',
    isSystem: true,
    url: '/export',
    description: '文件下载',
    modelId: null,
    reserved: true,
  },
];
